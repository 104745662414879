import './App.css';
import  Route from './components/Routes/Route'
function App() {
  return (
  <div>
    <Route/>
  </div>
  );
}

export default App;
